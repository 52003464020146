import { 
  PhotoIcon, 
} from '@heroicons/react/24/solid';
import { 
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  PaperAirplaneIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import ApplicationShell from "../../Components/ApplicationShell";
import { useEffect, useState, useRef, Fragment } from 'react';
import GlobalVars from '../../Config';
import { 
  Dialog, 
  Transition,
} from '@headlessui/react'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import app from '../../firebase';

const auth = getAuth(app);


export default function SubmitTicket() {
    // set title to Submit a Ticket
    document.title = "Submit a Ticket";

    // show submit message
    const [openSubmitMessage, setOpenSubmitMessage] = useState(false);
    const [success, setSuccess] = useState(false);  // true for success, false for error

    return (
        <>
        <ApplicationShell>
            <h1
                className="text-2xl font-bold mb-6"
            >Submit a Ticket</h1>
            <FormContent setOpenSubmitMessage={setOpenSubmitMessage} setSuccess={setSuccess} />
        </ApplicationShell>
        <SubmitMessageDialog open={openSubmitMessage} setOpen={setOpenSubmitMessage} success={success}  />
        </>
    )
}

// form content 
function FormContent({setOpenSubmitMessage, setSuccess}) {
    const [formData, setFormData] = useState({
      messageBody: "This is a message from Golden News, your ticket has been resolved.",
      email: localStorage.getItem('userEmail'),
      preferredNotificationMedia: "sms",
      organizationID: "6555c512db20a8030affbfcc",
    });

    const [uploadFiles, setUploadFiles] = useState(null);
    const [fileNames, setFileNames] = useState(null);
    useEffect(() => {
        // return if null
        if (!uploadFiles) return;
        
        setFileNames(Array.from(uploadFiles).map((file) => file.name));
    }, [uploadFiles]);

    let url = new URL(window.location.href);
    

    // get organizationID from url argument
    // const [orgName, setOrgName] = useState("");
    // let org = url.searchParams.get("org");
    // useEffect(() => {
    //     if (!org) return;

    //     fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/organization?organizationID=" + org)
    //     .then((response) => response.json())
    //     .then((data) => {
    //         setOrgName(data.name);
    //     });

    // }, [org]);

    // get locationID from url argument
    const [locationName, setLocationName] = useState("Galaxy Far Far Away");
    const [locationOption, setLocationOption] = useState([]);
    const [disableLocationOption, setDisableLocationOption] = useState(false);

    // get locationID from url argument
    let location = url.searchParams.get("loc");
    useEffect(() => {
        // return if location is null
        if (!location) return;

        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/location?locationID=" + location)
        .then((response) => response.json())
        .then((data) => {
            setLocationName(data.name);
        });
    }, [location]);

    // handle file upload
    const uploadBtn = useRef(null);
    const contactNumberInput = useRef(null);
    const issueDescriptionInput = useRef(null);
    const [isTesting, setIsTesting] = useState(false);

    // handle ticket submit
    const handleTicketSubmit = (e) => {
        e.preventDefault();
        let submitBody = formData;

        submitBody['testing'] = isTesting;

        // format check for email
        if (!formData.email || !formData.email.includes("@")) {
            alert("Please enter a valid email address. 請輸入有效的電郵地址。");
            return;
        }

        // check for name 
        if (!formData.name) {
            alert("Please enter a name. 請輸入名字。");
            return;
        }

        // show error if users forgot to enter description
        if (!formData.description) {
            alert("Please enter a description. 請輸入工單描述。");
            // focus on the description input
            issueDescriptionInput.current.focus();

            // change border color
            issueDescriptionInput.current.style.border = "2px solid red";
            return;
        } else {
            issueDescriptionInput.current.style.border = "1px solid #D1D5DB";
        }

        // show error if users forgot to enter contact number
        if (!formData.customerPhoneForEnquiry) {
            alert("Please enter a contact number");
            // focus on the contact number input
            contactNumberInput.current.focus();

            // change border color 
            contactNumberInput.current.style.border = "2px solid red";
            return;
        } else {
            contactNumberInput.current.style.border = "1px solid #D1D5DB";
        }

        // confirm users
        if (!window.confirm("Are you sure you want to upload this ticket?")) {
            return;
        } else {
          uploadBtn.current.disabled = true;
          uploadBtn.current.textContent = "Uploading...";
        }

        let postUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket";
        fetch(postUrl, {
            method: "POST",
            body: JSON.stringify(submitBody),
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                console.log(response.text());
                throw new Error("Error");
            }
        })
        .then((data) => {
            // return error if ticketID is not found
            if (!data.ticketID) {
                throw new Error("TicketID not found");
            }

            // return if there's no file to upload
            let files = [];
            if (!uploadFiles) {
                setOpenSubmitMessage(true);
                setSuccess(true);
                uploadBtn.current.disabled = false;
                uploadBtn.current.textContent = "Submit";
                return;
            } else {  // create a list of files with keys name and type
                for (let i = 0; i < uploadFiles.length; i++) {
                    files.push({
                        name: uploadFiles[i].name,
                        type: uploadFiles[i].type,
                    });
                }

            }           

            // fetch url for signed url
            let signedUrlPostUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket-attachment";
            fetch(signedUrlPostUrl, {
              method: "POST",
              body: JSON.stringify({
                ticketID: data.ticketID,
                files: files,
                organizationID: formData.organizationID,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response.text())
                    throw new Error("Error getting signed URL");
                }
            })
            .then((data) => {
              let urls = data.signedUrls;
              // use signed url to upload file
              let uploadPromises = urls.map((url, index) => {
                return fetch(url, {
                  method: "PUT",
                  body: uploadFiles[index],
                })
                .then((response) => {
                  if (response.ok) {
                    console.log("File uploaded successfully");
                    // show success message
                    setOpenSubmitMessage(true);
                    setSuccess(true);
                  } else {
                    throw new Error("Error uploading file");
                  }
                });
              });

              Promise.all(uploadPromises)
                .finally(() => {
                  uploadBtn.current.disabled = false;
                  uploadBtn.current.textContent = "Save";
                });
            })
            .catch((error) => {
                console.error("Error:", error);

                uploadBtn.current.disabled = false;
                uploadBtn.current.textContent = "Submit";

                // show failure message
                setOpenSubmitMessage(true);
                setSuccess(false);
            });
        })
        .catch((error) => {
            console.error("Error:", error);

            uploadBtn.current.disabled = false;
            uploadBtn.current.textContent = "Submit";

            // show failure message
            setOpenSubmitMessage(true);
            setSuccess(false);
        })
    }

  
    // This code sets up state and an effect to track the logged-in user's email
    const [loggedInEmail, setLoggedInEmail] = useState("");
    useEffect(() => {
      // Set up an auth state listener
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // If a user is signed in, set their email in state
          setLoggedInEmail(user.email);
        } else {
          // If no user is signed in, clear the email in state
          setLoggedInEmail("");
        }
      });
      
      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    }, []);

    const testModeBtnRef = useRef(null);
    useEffect(() => {
      // check if users email domain contains goldennews.com.hk
      if (loggedInEmail.includes("@goldennews.com.hk")) {
        testModeBtnRef.current.style.display = "flex";
        setIsTesting(true);
      } else {
        testModeBtnRef.current.style.display = "none";
        setIsTesting(false);
      }
    }, [loggedInEmail]);

    const [phoneSuggestion, setPhoneSuggestion] = useState([]);  // a list of client contact number suggestion
    // retrieve users info using given email 
    useEffect(() => {
        if (!formData.email) return;

        if (!loggedInEmail) {
          return 
        }

        // Check if the user is entering a goldennews domain email but didn't log in using a goldennews domain email
        if (formData.email.includes("@goldennews.com.hk") && loggedInEmail !== formData.email) {
            // Handle this case accordingly
            console.error("User is entering a goldennews domain email but didn't log in using a goldennews domain email");
            return;
        } 

        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/user?email=" + formData.email)
        .then((response) => response.json())
        .then((data) => {

            setFormData(prevFormData => ({
              ...prevFormData,
              name: data.name,
              userID: data.userID,
              customerPhoneForEnquiry: data.phone,
            }));

            // get locationOption with the given user id 
            let getUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/associated-location" + "?userID=" + data.userID;

            // if login users' email is @goldennews.com.hk using /api/v1/location
            if (formData.email.includes("@goldennews.com.hk")) {
                getUrl = GlobalVars.BACKEND_DOMAIN + "/api/v1/location";
            }

            fetch(getUrl)
            .then((response) => response.json())
            .then((data) => {
                if (data['message'] == 'Location not found') {
                    setDisableLocationOption(true);
                    setLocationOption([]);
                    return;
                }

                // filter out data where isActive exist and is false
                data = data.filter((location) => {
                    return location.isActive !== false;
                });

                // if langVar exists and contain langVar.tc, add to the name
                data = data.map((location) => {
                    if (location.langVar) {
                      if (location.langVar.tc) {
                        location.label = location.label + " (" + location.langVar.tc + ")";
                      }
                    } else {
                        location.label = location.label;
                    }
                    return location;
                });

                
                setLocationOption(data);

                // set the first location as the default location
                if (data.length > 0) {
                    setLocationName(data[0].label);
                    setFormData(prevFormData => ({
                      ...prevFormData,
                      locationID: data[0].value,
                    }));
                    setDisableLocationOption(false);
                } else {
                    setDisableLocationOption(true);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });

            
        });
        
        // Retrieve phone suggestion list 
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/user/related-phone-number?email=" + formData.email)
        .then((response) => response.json())
        .then((data) => {
            setPhoneSuggestion(data);
        })
        .catch((error) => {
            console.error("Error:", error);
        });


    }, [formData.email, loggedInEmail]);

    // update sms text when users updated location, job type
    useEffect(() => {
      let smsText = "This is a message from Golden News, your ticket has been resolved. ";
      
      if (formData.humanReadableID) {
        smsText += "\nTicket ID: " + formData.humanReadableID;
      }

      if (formData.selectedLoc) {
          smsText += "\nLocation: " + formData.selectedLoc.label;
      }

      if (formData.productType) {
          smsText += "\nProduct Type: " + formData.productType;
      }

      setFormData(prevFormData => ({
          ...prevFormData,
          messageBody: smsText,
      }));
  }, [formData.selectedLoc, formData.productType, formData.humanReadableID]);

    // get a potential human readable id by sending fetch request to (/api/v1/ticket/potential-human-readable-id)
    useEffect(() => {
        fetch(GlobalVars.BACKEND_DOMAIN + "/api/v1/ticket/potential-human-readable-id")
        .then((response) => response.json())
        .then((data) => {
            setFormData(prevFormData => ({
                ...prevFormData,
                humanReadableID: data.id,
            }));
        })
        .catch((error) => {
            console.error("Error:", error);
        });
    }, []);


    // handle file drag and drop
    const [isDragOver, setIsDragOver] = useState(false);

    const handleDragOver = (e) => {
      e.preventDefault(); // Prevent default behavior
      setIsDragOver(true); // Update state to indicate drag is happening
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      setIsDragOver(false); // Update state to indicate drag has left
    };

    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragOver(false); // Reset drag over state
      const files = e.dataTransfer.files; // Access the dropped files
      setUploadFiles(files); // Update state with the dropped files
    };

    return (
        <form>
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              {/* <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This information will be displayed publicly so be careful what you share.
              </p> */}
    
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                
                <div className="sm:col-span-4">
                    <div className="flex justify-between">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email
                        </label>
                        <span className="text-sm leading-6 text-red-500" id="email-optional">
                        Required
                        </span>
                    </div>
                    <div className="mt-2">
                        <input
                        type="email"
                        name="email"
                        id="email"
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="you@example.com"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        />
                    </div>
                </div>
                <div className="sm:col-span-4">
                    <div className="flex justify-between">
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Name
                        </label>
                        <span className="text-sm leading-6 text-red-500" id="name-optional">
                        Required
                        </span>
                    </div>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="name"
                        id="name"
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                    </div>
                </div>
                {/* add a location input but it's disabled */}
                <div className="sm:col-span-4">
                    <div className="flex justify-between">
                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
                        Location
                        </label>
                    </div>
                    <div className="mt-2">
                        <CreatableSelect
                          id="location-selector"
                          options={locationOption}
                          isDisabled={disableLocationOption}
                          value={formData.selectedLoc}
                          onChange={(selectedOption) => {
                            if (!selectedOption) {
                              setLocationName("");
                              setLocationOption([]);
                              setFormData({ ...formData, locationID: "" });
                              return;
                            }
                            setLocationName(selectedOption.label);
                            setFormData({ ...formData, locationID: selectedOption.value, selectedLoc: selectedOption });
                          }}
                        />
                    </div>
                </div>
                {/* add a number input for Client number optional for us to ask enquiry */}
                <div className="sm:col-span-full">
                    <div className="flex justify-between">
                        <label htmlFor="client-number" className="block text-sm font-medium leading-6 text-gray-900">
                          Client Contact Number
                        </label>
                        <span
                          className="text-sm leading-6 text-red-500"
                        >
                          Required
                        </span>
                    </div>
                    <div>
                      <span
                        className="text-sm leading-6 text-gray-600"
                      >Please provide a phone number where we can reach you for further inquiries related to your ticket.</span>
                    </div>
                    <div className="mt-2">
                        <input
                        type="number" 
                        name="client-number"
                        id="client-number"
                        className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.customerPhoneForEnquiry}
                        onChange={(e) => setFormData({ ...formData, customerPhoneForEnquiry: e.target.value })}
                        ref={contactNumberInput}
                        />
                    </div>
                    {/* show a list of phone number suggestion */}
                    {phoneSuggestion && phoneSuggestion.length > 0 && (
                      <div
                        className="mt-2"
                      >
                        <span
                          className="text-sm leading-6 text-gray-600"
                        >
                          Suggested phone numbers based on your past requests:
                        </span>
                        <div className="flex overflow-x-scroll">
                          {phoneSuggestion.map((phone, index) => (
                            <button
                              key={index}
                              type="button"
                              className="text-sm leading-6 text-indigo-600 bg-white rounded-full px-3 py-1 m-1 ring-indigo-600 ring-1 ring-inset hover:bg-indigo-600 hover:text-white duration-300"
                              onClick={() => {
                                setFormData({ ...formData, customerPhoneForEnquiry: phone });
                              }}
                            >
                              {phone}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
                {/* a dropdown menu for selection */}
                <div className="sm:col-span-full">
                  <ProductTypeSelection setFormData={setFormData} formData={formData} />
                </div>

                <div className="col-span-full">
                    <div className="flex justify-between">
                        <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                        Description
                        </label>
                        <span className="text-sm leading-6 text-red-500" id="email-optional">
                        Required
                        </span>
                    </div>
                    <p className="mt-3 text-sm leading-6 text-gray-600">Please provide all details above, including product and examples for us to troubleshoot, and we'll be in touch shortly.</p>
                    <div className="mt-2">
                      <textarea
                          id="description"
                          name="description"
                          rows={3}
                          className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          value={formData.description}
                          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                          ref={issueDescriptionInput}
                      />
                    </div>
                    <DescriptionWritingSuggestion 
                      setFormData={setFormData} 
                      formData={formData}
                    />
                </div>
    
                <div className="col-span-full">
                  <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                    Attach Files
                  </label>
                  <div 
                    className={`mt-2 flex justify-center rounded-lg border ${isDragOver ? 'border-indigo-600 border-2' : 'border-dashed border-gray-900/25'}  px-6 py-10 duration-300`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                  >
                    <div className="text-center">
                      <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                      <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload files</span>
                          <input 
                            id="file-upload" 
                            name="file-upload" 
                            type="file" 
                            className="sr-only" 
                            multiple 
                            onChange={(e) => setUploadFiles(e.target.files)}
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-xs leading-5 text-gray-600">PNG, JPG, XLSX, PDF, DOCX, PPTX, TXT up to 10MB</p>
                    </div>
                  </div>
                  {/* list out file names */}
                    {(fileNames && fileNames.length > 0) && (
                        <div className="mt-2">
                          <ul className="border border-gray-900/25 rounded-md divide-y divide-gray-900/25">
                              {Array.from(fileNames).map((file, index) => (
                              <li key={index} className="flex justify-between items-center px-3 py-2">
                                  <span className="text-sm leading-6 text-gray-900">{file}</span>
                                  <button
                                  type="button"
                                  className="text-sm leading-6 text-red-500"
                                  onClick={() => {
                                      // update fileNames
                                      setFileNames(fileNames.filter((fileName) => fileName !== file));
                                  }}
                                  >
                                  Remove
                                  </button>
                              </li>
                              ))}
                          </ul>
                        </div>
                    )}
                </div>
                <hr
                    className="col-span-full border-gray-900/25"
                ></hr>
                <TitleContainer
                  title={"Notification"}
                  alertTitle={"SMS/Phone Notification"}
                  alertMessage={"We will send you an alert when your ticket is RESOLVED. If there is not a contact in the contact list, we will ignore the setting."}

                />
                <ContactInfoCont 
                  formData={formData}
                  setFormData={setFormData} 
                />

                <hr
                    className="col-span-full border-gray-900/25"
                ></hr>
                <TitleContainer
                  title={"Work Arrangement"}
                />
                {/* a date and time input for staff to visit */}
                <div className="sm:col-span-4">
                    <div className="">
                        <label htmlFor="visit-time" className="block text-sm font-medium leading-6 text-gray-900">
                        Visit Time
                        </label>
                        <p
                          className="text-sm leading-6 text-gray-600"
                        >
                          For onsite task, if you would like the staff to visit at a specific date and time, please enter below.
                        </p>
                    </div>
                    <div className="mt-2">
                        <input
                        type="datetime-local"
                        name="visit-time"
                        id="visit-time"
                        className="px-2 bg-gray-100 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={formData.visitTime}
                        onChange={(e) => setFormData({ ...formData, visitTime: e.target.value })}
                        />
                    </div>
                </div>


                <hr
                    className="col-span-full border-gray-900/25"
                ></hr>
                {/* add an organization input but it's disabled */}
                {/* <div className="sm:col-span-4">
                    <div className="flex justify-between">
                        <label htmlFor="organization" className="block text-sm font-medium leading-6 text-gray-900">
                        Target Organization
                        </label>
                    </div>
                    <div className="mt-2">
                        <input
                        type="text"
                        name="organization"
                        id="organization"
                        className="bg-gray-100 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={orgName}
                        disabled
                        /> 
                    </div>
                </div> */}
              </div>
            </div>
          </div>
    
          <div 
            className="mt-6 flex items-center justify-end gap-x-6 bg-indigo-50 w-full py-4 px-7"
            style={{position: "fixed", bottom: "0px", right: "0px"}}
          >
            <div
              className="items-center gap-x-2 hidden"
              ref={testModeBtnRef}  
            >
              <input 
                type='checkbox'
                id='test-mode'
                name='test-mode'
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                checked={isTesting}
                onChange={(e) => setIsTesting(e.target.checked)}
              />
              <label
                htmlFor='test-mode'
                className="text-sm text-gray-900"
              >
                Test Mode
              </label>
            </div>
            <button
              type="button"
              className="duration-300 flex rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleTicketSubmit}
              ref={uploadBtn}
            >
              <PaperAirplaneIcon className="mr-1 h-5 w-5 text-white" aria-hidden="true" />
              Submit
            </button>
          </div>
        </form>
      )
}

// Submit message
function SubmitMessageDialog({open, setOpen, success}) {

  const handleClose = () => {
    // check if users has logged in with firebase
    onAuthStateChanged(auth, (user) => {
      // if not successful, just dismiss the dialog
      if (!success) {  
        setOpen(false);
        return;
      }

      if (user) {
        // User is signed in.
        // redirect to the home page
        window.location.href = "/tickets";
      } else {
        // No user is signed in.
        // redirect to the login page
        window.location.href = "/";
      }
    });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${success? "bg-green-100" : "bg-red-100"}`}>
                    {success ? (
                      <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    ) : (
                      <ExclamationCircleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    )  
                    }
                    
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Ticket {success ? "submitted" : "not submitted"}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {success ? "Your ticket has been submitted successfully." : "There was an error submitting your ticket."}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleClose}
                  >
                    Got it
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

// Title container
function TitleContainer({title, alertTitle, alertMessage}) {
  return (
    <div
      className="col-span-full"
    >
      <h2 className="text-lg font-semibold leading-7 text-gray-900">{title}</h2>
      {alertTitle || alertMessage ? (
      <div className="rounded-md bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-yellow-800">{alertTitle}</h3>
            <div className="mt-2 text-sm text-yellow-700">
              <p>
                {alertMessage}
              </p>
            </div>
          </div>
        </div>
      </div>
      ) : null}
    </div>
  )
}

// contact information container
// Users need to enter preferred notification media, message content, and a list of contact
function ContactInfoCont({formData, setFormData}) {
  const handleAddContact = () => {  
    let newContacts = formData.contacts ? [...formData.contacts] : [];
    newContacts.push("98765432");
    setFormData({ ...formData, contacts: newContacts });
  }


  return (
    <>
    <div className="col-span-full">
      <label htmlFor="preferred-notification-media" className="block text-sm font-medium leading-6 text-gray-900">
        Preferred Notification Media
      </label>
      <div className="mt-2">
        <select
          id="preferred-notification-media"
          name="preferred-notification-media"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={formData.preferredNotificationMedia}
          onChange={(e) => setFormData({ ...formData, preferredNotificationMedia: e.target.value })}
        >
          <option value="sms">SMS</option>
          <option value="phone">Auto Phone Call by System</option>
        </select>
      </div>
      {/* message body */}
      <div className="mt-6">
        <label htmlFor="message-body" className="block text-sm font-medium leading-6 text-gray-900">
          Message Body
        </label>
        <p
          className="mt-1 text-sm leading-6 text-gray-600"
        >
          You can customize how the message will look like. The message will be sent when the ticket is resolved.
        </p>
        <div className="mt-2">
          <textarea
            id="message-body"
            name="message-body"
            rows={3}
            className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={formData.messageBody}
            onChange={(e) => setFormData({ ...formData, messageBody: e.target.value })}
          />
        </div>
      </div>
      {/* expandable contact list inputs */}
      <div
        className="mt-6"
      >
     
      <label
        htmlFor="contact-list"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Contact List
      </label>
      <p
        className="mt-1 text-sm leading-6 text-gray-600"
      >
        Please enter a list of contacts to receive notifications. The format must be 8 digits Hong Kong phone number.
      </p>
      {formData.contacts && formData.contacts.map((contact, index) => (
        <ContactInfo key={index} contact={contact} setFormData={setFormData} index={index} formData={formData} />
      ))}
      <div className="mt-2">
        <button
          type="button"
          className="text-sm leading-6 text-indigo-600"
          onClick={handleAddContact}
        >
          Add a New Contact
        </button>
        <button
          type="button"
          className="text-sm leading-6 text-red-500 ml-2"
          onClick={() => {
            let newContacts = formData.contacts;
            newContacts.pop();
            setFormData({ ...formData, contacts: newContacts });
          }}
        >
          Delete the Last Contact
        </button>
      </div>
      </div>

    </div>

    </>
  )
}

function ContactInfo({contact, setFormData, index, formData}) {
  const inputRef = useRef(null);
  // if users enter more than 8 digits, show an error message
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    // check if contact is a valid phone number
    if (contact.length > 8) {
      setError(true);
      setErrorMsg("Phone number must be 8 digits or less.");
    // must start with 2, 3, 5, 6, 9
    } else if (contact[0] != "5" && contact[0] != "6" && contact[0] != "9" && contact[0] != "2" && contact[0] != "3") {
      setError(true);
      setErrorMsg("Phone number must start with 2, 3, 5, 6, or 9.");
    } else {
      setError(false);
    }
  }, [contact]);

  return (
    <div className="col-span-full">
      <div className="flex justify-between">
        <label htmlFor="contact" className="block text-sm font-medium leading-6 text-gray-900">
          
        </label>
      </div>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="number"
          name="contact"
          id="contact"
          className="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          value={contact}
          ref={inputRef}
          onChange={(e) => {
            let newContacts = [...formData.contacts];
            newContacts[index] = e.target.value;
            setFormData({ ...formData, contacts: newContacts });
          }}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {error && (
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          )}
        </div>
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errorMsg}
        </p>
      )}
    </div>
  )
}


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// product type selection
function ProductTypeSelection({formData, setFormData}) {
  const [displayLabel, setDisplayLabel] = useState("Product Type");
  const [items, setItems] = useState(GlobalVars.productType);
  useEffect(() => {
    // pick the first item as the default item
    setFormData({ ...formData, productType: items[0]['value'] });
  }, []);

  return (
    <>
      <label htmlFor="product-type" className="mb-1 block text-sm font-medium leading-6 text-gray-900">
        Product Type
      </label>
      <Select
        options={items}
        value={formData.productType ? {label: formData.productType, value: formData.productType} : null}
        onChange={(e) => setFormData({ ...formData, productType: e.value })}
        isSearchable
        id="product-type-selector"
      />
    </>
  )
}

// description writing suggestion
function DescriptionWritingSuggestion({formData, setFormData}) {
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // Inside your component
  const debounceTimerRef = useRef(null);

  // fetch suggestion from the backend
  useEffect(() => {
    // Clear existing timer
    clearTimeout(debounceTimerRef.current);

    console.log("fetching suggestion");

    debounceTimerRef.current = setTimeout(() => {
      setIsLoading(true);
      let url = GlobalVars.AIASSIST_DOMAIN + "/api/v1/groq/ticket-remark-assistant";
  
      // Add location id 
      if (formData.selectedLoc && formData.selectedLoc.value.length == 24) {
        url += "?locationID=" + formData.selectedLoc.value;
      } else {
        return;
      }

      // add product Type
      if (formData.productType) {
        url += "&productType=" + formData.productType;
      }

      // add description
      if (formData.description) {
        url += "&description=" + formData.description;
      }
  
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          setSuggestions(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, 500); // 500ms debounce time
  
    // Cleanup function to clear the timer when the component unmounts or re-renders
    return () => clearTimeout(debounceTimerRef.current);
  }, [formData.selectedLoc, formData.description, formData.productType]);

  // handle button press and add text to the description
  const handleAddText = (text) => {
    let newText = text;
    if (formData.description != "" && formData.description != null) {
      newText = formData.description + " " + newText;
    }
    console.log(newText)
    setFormData({ ...formData, description: newText });
  }

  return (
    // create a list of buttons with the item in suggestions
    <div
      
    >
      <span
        className="text-sm leading-6 text-gray-900 w-full"
      >
        Suggested Text:
      </span>
      {isLoading ? (
        <div className="ml-2">
          <div className="spinner-border text-indigo-600" role="status">
            <ArrowPathIcon className="h-5 w-5 text-indigo-600 animate-spin" aria-hidden="true" />
          </div>
        </div>
      ) : (
      <>
        {suggestions.length > 0 &&
          suggestions.map((suggestion, index) => (
            <button
              key={index}
              type="button"
              className="suggested-description-options text-sm leading-6 text-indigo-600 bg-white rounded-full px-3 py-1 m-1 ring-indigo-600 ring-1 ring-inset hover:bg-indigo-600 hover:text-white duration-300"
              onClick={() => handleAddText(suggestion)}
            >
              {suggestion}
            </button>
          ))
          }
        </>
      )}
    </div>
  )
}

